<template>
  <div>
    <section>
      <div class="container">
        <div class="w-full flexCon">
          <div class="w-half">
            <h1>The Power to keep your promises</h1>
            <p class="f-18">Empower your business to always deliver.</p>
            <ul>
              <li>Up to 12 months instalment payment</li>
              <li>Warranty on all products</li>
              <li>After-sales service & maintenance</li>
            </ul>
            <a href="https://bit.ly/47iREAP">
              <button class="btn btn-imp-secondary bg-primary">
                Get Started
              </button>
            </a>
          </div>
          <div class="w-half">
            <img src="@/assets/images/sme.png" class="w-full" alt="" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped >
.w-full {
  width: 100%;
}
.flexCon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  .w-half {
    width: 50%;
    h1 {
      font-weight: 600;
    }
    p {
      margin-top: 12px;
    }
    button {
      margin-top: 24px;
      color: #fff;
    }
  }
}

@media screen and (max-width: 768px) {
  .flexCon {
    padding: 4rem 0;
    flex-direction: column;
    .w-half {
      width: 100%;
    }
  }
}
</style>